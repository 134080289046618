import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import './../assets/css/art80.css'

import subWhiteStroke from './../assets/img/subtitle_white_stroke.svg'
import articleArrow from './../assets/img/article_arrow.svg'
import article from './../assets/img/article.svg'
import article80 from './../assets/img/article80.pdf'

const Art80 = () => {
  return (
    <>
      <Helmet>
        <title>Article 80 LFSS | Paramedic</title>
      </Helmet>
      <section id='section_art80_home'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='title col-lg-7'>
              <h1>Récapitulatif de l’article 80<br />de la LFSS sur la réforme du<br />transport sanitaire</h1>
              <img
                src={subWhiteStroke} alt='Traits blancs à bouts arrondis'
                className='subtitle_white_stroke' data-aos='fade-left' data-aos-duration='1000' data-aos-delay='500'
                draggable='false'
              />
              <br />
              <Link to='#main_art80' data-aos='fade-left' data-aos-duration='1000' data-aos-delay='750' className='article_arrow'>
                <img src={articleArrow} alt='fleche blanche orientée vers le bas' />
              </Link>
            </div>
            <div className='image col-lg-5'>
              <img src={article} alt="Illustration d'un article" draggable='false' />
            </div>
          </div>
        </div>
      </section>

      <section id='main_art80'>
        <div className='container'>
          <div className='row'>
            <p>La loi de financement de la sécurité sociale (LFSS) applique, depuis le 1er octobre 2018, la réforme
              dite
              de l’article 80. Cette disposition vise à «&nbsp;unifier les modalités de prise en charge des
              dépenses
              de transports inter et intra-hospitaliers et de préciser les règles concernant la prise en charge
              des
              transports des patients bénéficiant des permissions de sortie&nbsp;».
            </p>
            <br />
            <p>Auparavant, les transports de patients entre établissements étaient financés selon le motif du
              déplacement. D’une part, on distinguait ceux gérés par l’enveloppe des soins de ville. Ils
              concernaient
              les transports provisoires pour la réalisation d’une séance de chimiothérapie, de dialyse ou de
              radiothérapie; ainsi que les transferts définitifs entre deux entités juridiques. D’autre part, on
              répertoriait les transports assurés par les établissements de santé. Ils visaient les transferts
              provisoires hors séances tout comme les transports entre deux entités géographiques d’une même
              entité
              juridique.
            </p>
            <br />
            <p className='text-center' style={{ width: '100%', margin: '1.5rem 0 3rem 0' }}>
              <strong>Retrouvez l’infographie de
                l’article 80
              </strong><br />
              <a className='btn btn-primary mt-2' href={article80}>Télécharger gratuitement</a>
            </p>
            <h1><span style={{ fontWeight: '400' }}>Les dépenses des transports à la charge des établissements</span>
            </h1>
            <h2>Une mesure qui touche tant les transports provisoires que définitifs</h2>
            <p>
              <span style={{ fontWeight: '400' }}>Dorénavant, les transports réalisés au sein d’un même établissement
                ou
                entre deux institutions sont assumés par l’établissement à l’origine de la prescription du
                transport. Cette mesure sous-tend que les courses ne sont plus facturables à l’assurance maladie
                (AM).<br />
              </span>La réforme touche tant les transports provisoires (moins de 48h) que définitifs (plus de
              48h),
              qu’ils relèvent de deux entités géographiques d’une même entité juridique ou de deux entités
              juridiques
              distinctes.<br />
              <span style={{ fontWeight: '400' }}>Toutefois, il reste impératif que le patient transféré ait le statut
                de
                hospitalisé au moment du transfert.<br />
              </span><i><span style={{ fontWeight: '400' }}>A contrario</span></i><span style={{ fontWeight: '400' }}>,
                lors
                du transport d’un patient non hospitalisé vers un autre établissement en vue d’une admission
                pour
                hospitalisation, la course reste facturable à l’AM.
                                                                                  </span>
            </p>
            <h3>L’établissement prescripteur</h3>
            <p>
              <span style={{ fontWeight: '400' }}>Pour rappel, l’établissement prescripteur (et donc celui assumant la
                charge du transport) correspond à l’établissement depuis lequel le patient hospitalisé est
                transféré.
              </span>
            </p>
            <br />
            <p><span style={{ fontWeight: '400' }}>Ce principe général connaît deux exceptions : </span></p>
            <ul>
              <li style={{ fontWeight: '400' }}>
                <span style={{ fontWeight: '400' }}>en cas de transfert provisoire entre
                  deux
                  établissements pour réalisation d’une séance de chimiothérapie, de dialyse en centre ou de
                  radiothérapie, l’établissement responsable de la prescription et de la commande est
                  l’établissement vers lequel le patient est envoyé ;
                </span>
              </li>
              <li style={{ fontWeight: '400' }}>
                <span style={{ fontWeight: '400' }}>en cas de transfert provisoire entre
                  deux
                  établissements relevant de champs &nbsp;d’activité différents pour la réalisation d’une
                  prestation d’hospitalisation (PIA séjour), l’établissement responsable de la prescription et
                  de
                  la commande est celui vers lequel le patient est envoyé.”
                </span>
              </li>
            </ul>
            <h3>Prestation inter-activités (PIA)</h3>
            <p>
              <span style={{ fontWeight: '400' }}>La notion de «&nbsp;prestation inter-activités&nbsp;» qualifie une
                situation dans laquelle une unité d’hospitalisation utilise un plateau technique ou les
                équipements
                d’une autre unité d’hospitalisation qui relève d’un champ d’activité différent. Cette mesure
                assure
                au patient des soins ou examens qu’il ne peut pas effectuer lui même.
              </span>
            </p>
            <br />
            <p>
              <span style={{ fontWeight: '400' }}>Il faut toutefois distinguer les prestations réalisées à titre
                externe
                des actes encourant une admission en hospitalisation :
              </span>
            </p>
            <ul>
              <li>
                <span style={{ fontWeight: '400' }}>Les «&nbsp;actes et consultations externes&nbsp;» ne sont pas
                  facturables à l’assurance maladie par le prestataire ;
                </span>
              </li>
              <li><span style={{ fontWeight: '400' }}>les prestations qui suscitent une admission en hospitalisation
                (maximum une nuit) sont facturables à l’assurance maladie par le prestataire.
                  </span>
              </li>
            </ul>
            <h3>Les transports liés à des permission de sortie</h3>
            <p><span style={{ fontWeight: '400' }}>La question de la permission de sortie était à éclaircir. Pour
              rappel,
              une permission de sortie concerne un retour à domicile d’une durée maximale de 48h.
               </span>
            </p>
            <ul>
              <li>A visée thérapeutique</li>
            </ul>
            <p><span style={{ fontWeight: '400' }}>Un patient étant hospitalisé, une autorisation peut être accordée par
              le
              directeur de l’établissement et suivie par un avis favorable du médecin chef de service.<br />
            </span><span style={{ fontWeight: '400' }}>Dans cette mesure, la sortie peut être appliquée pour visée
                 thérapeutique. Elle s’affilie alors à une démarche médicale et la charge incombe à
                 l’établissement
                 de santé.</span>
            </p>
            <ul>
              <li>A la demande du patient</li>
            </ul>
            <p>Par ailleurs, la permission de sortie peut être réclamée par le patient. Dans ce cas, elle ne découle
              pas
              d’un fondement médical. Le transport sera à la charge du patient et non remboursable par l’assurance
              maladie. Toutefois, on ne distingue plus «&nbsp;visée thérapeutique&nbsp;» et «&nbsp;exigence
              particulière&nbsp;» pour les individus de moins de vingt ans. &nbsp;Ces transports sont
              intégralement
              pris en charge par l’Assurance Maladie.
            </p>
            <h3>Les transports de patients hospitalisés pour la réalisation de prestations «&nbsp;hors les
              murs&nbsp;»
            </h3>
            <p><span style={{ fontWeight: '400' }}>Tout transport provisoire d’un patient d’un patient hospitalisé pour
              la
              réalisation d’une prestation en dehors des murs de l’établissement incombe au prescripteur.
              Toutefois, dans le cadre d’une séance de radiothérapie dans un centre autre, le transport
              demeure
              facturable à l’AM.
               </span>
            </p>
            <h3>Les exceptions qui enfreignent la règle</h3>
            <p><span style={{ fontWeight: '400' }}>Le principe général reste le suivant : «&nbsp;tout transport d’un
              patient
              déjà hospitalisé n’est plus facturable à l’assurance maladie mais pris en charge par
              l’établissement
              prescripteur&nbsp;». Cependant, on relève quelques exceptions :
               </span>
            </p>
            <ul>
              <li><span style={{ fontWeight: '400' }}>les transports réalisés entre deux établissements en avion ou en
                bateau ;
                  </span>
              </li>
              <li><span style={{ fontWeight: '400' }}>les transports effectués dans le cadre de l’aide médicale
                d’urgence
                ;
                  </span>
              </li>
              <li><span style={{ fontWeight: '400' }}>les transports de patients non hospitalisés ;</span></li>
              <li><span style={{ fontWeight: '400' }}>les transports réalisés par le SMUR ;</span></li>
              <li><span style={{ fontWeight: '400' }}>les transports prescrits dans une structure d’hospitalisation à
                domicile (HAD) sauf exceptions :<br />
              </span><span style={{ fontWeight: '400' }}>– les transports prescrits pour des soins prévus au
                    protocole
                    de soins ;<br />
                         </span><span style={{ fontWeight: '400' }}>– les transports non prévus au protocole de soins si
                       l’état
                       du patient le justifie et dans le cadre du mode de prise en charge principal ou associé en
                       cours
                       au moment de la prescription ;</span>
              </li>
              <li><span style={{ fontWeight: '400' }}>les transports depuis ou vers un EHPAD sauf exceptions :<br />
              </span><span style={{ fontWeight: '400' }}>– si le transfert est effectué entre deux structures
                    relevant
                    d’une même entité géographique, le transport est prise en charge par l’établissement
                    prescripteur ;<br />
                         </span><span style={{ fontWeight: '400' }}>– si dans le cadre d’une permission de sortie, un
                       transfert
                       de moins de 48h est accompli d’un établissement de santé vers un EHPAD, le transport revient
                       à
                       la charge du prescripteur ;</span>
              </li>
              <li><span style={{ fontWeight: '400' }}>les transports depuis ou vers une unité de soins de longue durée
                (USLD) sauf exception :<br />
              </span><span style={{ fontWeight: '400' }}>– les transports réalisés entre deux établissements d’une
                    même entité géographique incombent à l’établissement prescripteur ;</span>
              </li>
              <li><span style={{ fontWeight: '400' }}>les transports provisoires pour la réalisation d’une séance de
                radiothérapie en centre de santé ou dans une structure d’exercice libéral.
                  </span>
              </li>
            </ul>
            <h1>Quels impacts sont attendus auprès des établissements de santé ?</h1>
            <h2>Les établissements de santé recourent à des procédures de marché public ou contrats de prestations
            </h2>
            <p><span style={{ fontWeight: '400' }}>Les relations entre les transporteurs et les établissements de santé
              sont
              en sol mouvant. Les établissements de santé, par le biais de leur groupe hospitalier de
              territoire
              (GHT), font appel à une procédure de marché public. Les établissements privés, quant à eux, se
              munissent de contrats de prestation.
               </span>
            </p>
            <br />
            <p><span style={{ fontWeight: '400' }}>Afin de les aiguiller dans leurs choix, le ministère encourage les
              établissements à être attentifs. Il souligne notamment deux éléments :&nbsp;
               </span>
            </p>
            <h3>Les modalités d’évaluation des offres</h3>
            <p><i /><span style={{ fontWeight: '400' }}>Tout d’abord, les modalités d’évaluation des offres doivent
              être
              prises en compte par les établissements. Le ministère conseille de rester vigilant à la
              «&nbsp;qualité technique et proposition organisationnelle&nbsp;». Il est important de prendre
              connaissance de l’organisation technique tout comme administrative.
                    </span>
            </p>
            <br />
            <p><span style={{ fontWeight: '400' }}>Paramedic, plateforme qui met en relation les établissements de santé
              et
              les sociétés d’ambulance, décharge les établissements de santé de ces tâches. Elle diminue leur
              charge de travail, simplifie la prise de commande, le suivi, la transmission des informations
              patients ainsi que la facturation. Utiliser Paramedic est un gain de temps pour les
              établissements
              de santé ce qui leur permet d’assurer un service de qualité. Travailler dans l’urgence diminue
              la
              qualité des soins fournis par les professionnels santé. En déléguant l’organisation des
              transports à
              Paramedic, les établissements peuvent se concentrer sur les malades. Un suivi en temps réel
              permet
              aux professionnels d’être avertis &nbsp;sur l’avancé des véhicules sanitaires, de la prise en
              charge
              à la destination. Les commandes peuvent être commandés instantanément ou planifiées. Cette
              méthode
              garantit la meilleure prise en charge possible.
               </span>
            </p>
            <br />
            <p><span style={{ fontWeight: '400' }}>Aussi, la présence d’un service logistique de réponse et/ou une
              interface
              entre le service centralisé de commandes de transports de l’établissement et les transporteurs
              sont
              des éléments importants à prendre en considération. Paramedic assure une assistance téléphonique
              pour aider les établissements de santé au quotidien. Ces derniers ne sont plus seuls face à la
              course aux transports, Paramedic les assiste et les soutient.
               </span>
            </p>
            <h3>Les éléments tarifaires</h3>
            <p><span style={{ fontWeight: '400' }}>Par ailleurs, il ne faut pas oublier que l’objectif premier de cette
              réforme reste de faire des économies. Ainsi, les éléments tarifaires restent un point
              primordial.
              Les établissements ont la responsabilité d’opter pour un modèle économique dans le cadre du
              marché.
              Ils doivent faire recours aux transports sanitaires dans la mesure où ces derniers sont
              indispensables. Aussi, ils doivent proposer des moyens de locomotion adaptés aux besoins du
              patient.
              Si l’individu se contente d’un transport assis, il n’est pas nécessaire de lui prescrire un
              transport allongé, plus coûteux. Paramedic met à disposition des véhicules adaptés qu’il
              s’agisse
              d’une ambulance, d’un VSL ou d’un taxi conventionné. De ce fait, les établissements limitent les
              dépenses. Pour suivre ces dernières de manière régulière et ne pas avoir de mauvaises surprises,
              Paramedic fournit un reporting. Les frais liés aux transports sont contrôlés et gérés.
               </span>
            </p>
            <br />
            <p><span style={{ fontWeight: '400' }}>Ainsi, les établissements doivent avoir recours à une offre de
              transports
              conséquente et organisée. Cette mesure permet de réduire le temps d’attente tout comme les
              éventuels
              retours «&nbsp;à vide&nbsp;». Ces courses demeurent inutiles et onéreuses. Par le biais d’un
              formulaire de demande simplifié, Paramedic envoie aux établissements de santé les véhicules les
              plus
              proches du lieu de prise en charge. Ainsi, le temps d’attente est réduit, et les retours
              «&nbsp;à
              vide&nbsp;» évités.
               </span>
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default Art80
